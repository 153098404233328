@font-face {
  font-family: Gotham-Light;
  src: url("../family/Gotham-Light.otf");
}
@font-face {
  font-family: Gotham-Medium;
  src: url("../family/Gotham-Medium.otf");
}

@font-face {
  font-family: Gotham-Bold;
  src: url("../family/Gotham-Bold.otf");
}
html,
body {
  font-family: Gotham-Medium;
  overflow: auto;
}
a:hover {
  color: #fff;
}
input {
  outline: none;
  border: none;
}
._title {
  color: #f4f3f9;
  font-family: Gotham-Medium;
}
._active {
  color: #a851ff;
  font-family: Gotham-Medium;
}
._active:hover {
  color: #a851ff;
}

._nav-title {
  color: #827f92;
}
._text {
  color: #a39fad;
  font-family: Gotham-Light;
}
._tip {
  color: #6e697b;
}
._tip-yellow {
  color: #e7bb41;
}
._border {
  border: 1px solid #2a2539;
}
._borderS {
  border: 1px solid rgba(255, 255, 255, 0.25);
}
._borderS:hover {
  background: #ff4b01;
}
._line {
  border-bottom: 1px solid #2a2539;
}
._background1 {
  background-color: #0b051a;
}
._background2 {
  background-color: #1c172a;
}
._background3 {
  background-color: #111;
}
._background-gradient {
  background: linear-gradient(#181327, #361a58);
}

._background-gradient2 {
  background: linear-gradient(to right, #ce00ff, #7f00ff);
}

._background-gradient3 {
  background: linear-gradient(to right, #361a58, #181327);
}

._background-gradient4 {
  background: linear-gradient(to right, #1c172a 52.13%, #331951 101.14%);
}
._background-code {
  /* background-color: rgba(42, 37, 57, 1); */
  border: 1px solid #3d3952;
}
.ant-progress .ant-progress-text {
  font-size: 12px;
  color: white;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background: transparent;
  color: #fff;
  border-radius: 30px;
  width: 70px;
  border-color: #3d3952;
}
.epochSelect {
  color: #111;
  border: 1px solid #999;
  box-shadow: none;
}
.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #3d3952;
}
.ant-select-selector:hover {
  border-color: #3d3952 !important;
}

.ant-select-dropdown {
  font-size: 12px;
  width: 140px !important;
  border: 1px solid #3d3952;
  background: #1c172a;
  text-align: left;
  padding: 6px;
}
.ant-select-dropdown .ant-select-item {
  color: #fff;
  font-weight: 100;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
  color: #fff;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff;
}
.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #2a2539;
}

._popover {
  width: 300px;
  font-family: Gotham-Light;
}
._popover .ant-popover-inner {
  background-color: rgba(61, 57, 82, 1);
}
._popover .ant-popover-inner-content {
  color: rgba(244, 243, 249, 1);
  word-break: break-all;
}
._popover .ant-popover-arrow:before {
  background-color: rgba(61, 57, 82, 1);
}
.ant-modal-root .ant-modal-content {
  font-family: Gotham-Medium;
  background-color: #121212;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}
.ant-modal .ant-modal-title {
  background-color: #121212;
  color: #fff;
  text-align: left;
  padding-top: 2px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}
.ant-btn {
  font-family: Gotham-Medium;
  border: none;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  font-family: Gotham-Medium;
}

.ant-notification-topRight.ant-notification-stack
  > .ant-notification-notice-wrapper {
  top: 80px;
}
.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-progress.ant-progress-show-info .ant-progress-outer {
  margin-inline-end: 0px;
  padding-inline-end: 0px;
}
.ant-progress .ant-progress-inner {
  width: 100%;
  background-color: #322e2d;
  /* border: 1px solid rgba(168, 81, 255, 0.4); */
}
.ant-progress-text {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 14px);
  font-family: Gotham-Light;
  font-size: 12px;
}
.ant-progress .ant-progress-bg {
  height: 4px !important;
  background: linear-gradient(270deg, #ffffff 0%, #ff4b01 100%);
  /* box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.6) inset; */
}
._yellow {
  color: #e6a62c;
  font-family: Gotham-Medium;
}
._inputStyle {
  font-family: Gotham-Medium;
}
._borderT {
  border-top: 1px solid #1c172a;
}
._table {
  font-family: Gotham-Light;
}

._tableTitle {
  color: #6c697b;
}

.tableBtn {
  border-radius: 16px;
  padding: 0 30px;
  border: 1px solid #9c00ff;
  background: #361a58 !important;
  color: #fff;
}
.tableBtn:hover {
  border: 1px solid #9c00ff !important;
}
._w100 {
  width: 49%;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.06) 25%,
    rgba(255, 255, 255, 0.15) 37%,
    rgba(255, 255, 255, 0.06) 63%
  );
  background-size: 400% 100%;
}
.ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100% !important;
  height: 210px;
  border-radius: 16px;
  margin-bottom: 20px;
}

.ant-btn-default:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: #a39fad;
  border: #3d3952;
}
/* .ant-btn:hover {
  background-color: #434356 !important;
  color: #fff !important;
} */
._winNumber {
  font-size: 20px;
  color: #a851ff;
}
._roundStatus {
  height: 24px;
  line-height: 23px;
  padding: 0 12px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 12px;
  font-weight: 600;
}
._roundStatus1 {
  background-color: #e03dee;
}
._roundStatus2 {
  background-color: #1eaf67;
}
._roundStatus3 {
  background-color: #6c697b;
}
._roundStatus4 {
  background-color: #a851ff;
}
._roundStatus5 {
  background-color: #e6a62c;
}
.ant-btn-default {
  background: #ff4b01;
  color: #fff;
}

._background-gradient5 {
  background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #1c172a 100%);
}

._background-gradient6:hover {
  background: #361a58;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  text-align: left;
  color: #f4f3f9;
}
.ant-select-dropdown .ant-select-item-option-content {
  padding-right: 6px;
}
._tutorialsInfo {
  width: 560px;
  height: 277px;
  background: linear-gradient(
    180deg,
    rgba(28, 23, 42, 0.69) 1.44%,
    rgba(43, 36, 61, 0.35) 100%
  );
}

.accountInfo {
  inset: 60px 20px auto auto !important;
}
.accountInfo .ant-popover-content {
  width: 306px;
  height: 206px;
}

.accountInfo .ant-popover-inner-content {
  color: #f4f3f9;
}

.accountInfo .ant-popover-inner {
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
}

.langList {
  inset: 70px 10px auto auto !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.langList .ant-popover-content {
  /* width: 100px; */
  max-height: 200px;
  overflow: auto;
}
.langList .ant-popover-inner-content {
  color: #f4f3f9;
}

.langList .ant-popover-inner {
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
  background-color: #1c172a !important;
}
._langList {
  border-bottom: 1px solid #2a2539;
}
._langList .active {
  background-color: #2a2539;
}

._langList:last-child {
  border: none;
}

._borderS1 {
  border: 1px solid #a851ff;
}

._borderS2 {
  border: 1px solid #a851ff;
}

.communityList {
  padding-top: 20px !important;
}
.communityList .ant-popover-inner {
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
}

.communityList .ant-popover-inner-content {
  color: #f4f3f9;
}
.communityList .list:hover {
  background-color: #2a2539;
}
.closeWallet {
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-image: url("../../asserts/imgs/closeWallet.png");
  background-position: center center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
}
.closeWallet:hover {
  background-image: url("../../asserts/imgs/closeWalletHover.png");
}
.ant-notification {
  z-index: 111111;
}
.ant-notification-topRight.ant-notification-stack
  > .ant-notification-notice-wrapper {
  border-radius: 10px;
  background-color: #2a2539;
}
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  background-color: #2a2539;
  padding: 18px 20px 10px 20px;
  border-radius: 10px;
  font-family: Gotham-Medium;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-content
  .ant-notification-notice-message {
  color: #fff;
  font-size: 14px;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close {
  top: 18px;
  right: 20px;
  color: #f5f5f5;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-close:hover {
  color: #fff;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-icon {
  font-size: 20px;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  margin-inline-start: 30px;
}

._carouselBtn {
  top: 160px;
}

.beatBox {
  animation: beat 1.5s infinite;
}
@keyframes beat {
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}
.ant-collapse {
  border: none;
  font-family: Gotham-Light;
  font-size: 16px;
}
.ant-collapse > .ant-collapse-item {
  border: none;
  background-color: #1c172a;
  margin-top: 20px;
  border-radius: 10px;
  padding: 3px 20px;
}
.ant-collapse .ant-collapse-content {
  background-color: transparent;
  border-top: 1px solid #3d3952;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 10px 0 12px 0;
  align-items: center;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  color: #f4f3f9;
  font-weight: bold;
}
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px 0 10px 0;
  color: #a39fad;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 8px;
}
.ant-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  flex-shrink: 0;
}

.orange {
  color: #f7931a;
  font-weight: 500;
}
.green {
  color: #50af95;
}
#dynamic {
  display: none !important;
}
.wrongNetwork {
  color: #fff;
  top: 60px !important;
}

.wrongNetwork .ant-popover-inner-content {
  color: #fff;
}

.wrongNetwork .ant-popover-inner {
  padding: 8px 10px;
}
._tabsBg {
  background-color: #191428;
  border-radius: 30px;
}
._tabActive {
  border-radius: 30px;
  background: linear-gradient(
    90deg,
    rgba(206, 0, 255, 0.8) 0%,
    rgba(127, 0, 255, 0.8) 100%
  );
}

.contentHome {
  min-height: 100vh;
  background-image: url("../imgs/homeBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 60px;
}
.content {
  width: 100%;
  min-height: 100vh;
  background-color: #0d0705;
  padding-top: 60px;
}
._header {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
}

._links {
  height: 40px;
  background: rgba(216, 216, 216, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: inset 0px 1px 15px 8px rgba(255, 255, 255, 0.02);
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
}
._activeLink {
  color: #fff;
}
._titleLink {
  color: rgba(255, 255, 255, 0.5);
}
.launchApp {
  height: 40px;
  background: #ff4b01;
  border-radius: 10px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
  padding: 0 15px;
}
.launch {
  max-width: 600px;
  min-width: 400px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translate(-50%);
  max-width: 90%;
}
.dashboard {
  max-width: 80%;
  margin: 0 auto;
  background-image: url("../../asserts/imgs/dashbord.png");
  background-repeat: no-repeat;
  background-position: right -130px;
  background-size: 600px;
}
.dashboardTitle {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-top: 100px;
}
.gradient {
  background: linear-gradient(340deg, #ffffff 0%, #ff4b01 100%);
  color: transparent;
  background-clip: text;
}
.active {
  color: #ff7339;
}
.green {
  color: #c4ff66;
}
.assetsBox {
  background-color: #0d0705;
}
.border {
  /* background: #121212; */
  background: linear-gradient(180deg, rgba(41, 41, 41, 0) 0%, #0e0e0e 100%);
  border-radius: 20px;
  border: 1px solid #322e2d;
}
.smBtn {
  font-family: Gotham-Light;
  padding: 2px 10px;
}
.boxList {
  width: 49.5%;
}
.Light {
  font-family: Gotham-Light;
}
.Medium {
  font-family: Gotham-Medium;
}
.text {
  color: rgba(255, 255, 255, 0.5);
}
.tables {
  width: 100%;
  margin-top: 20px;
}
.tables thead {
  font-size: 14px;
  font-family: Gotham-Light;
  color: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.tables thead tr th {
  padding-bottom: 10px;
}
.tables tbody tr td {
  padding-top: 16px;
  padding-bottom: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.arrow_top {
  width: 0;
  height: 0;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  /* border: 30px solid #ff7f0b; */
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  font-size: 0;
  line-height: 0;
}
.arrow_bottom {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  font-size: 0;
  line-height: 0;
  transform: rotate(180deg);
}
.btn {
  border: 1px solid #322e2d;
  border-radius: 10px;
}
.activeBtn {
  background-color: #ff4b01;
}

.ant-switch.ant-switch-checked {
  background: #a9ff66;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #a9ff66;
}
.ant-switch:hover:not(.ant-switch-disabled) {
  background: #8d8b8b;
}
.ant-switch .ant-switch-handle::before {
  background: #0d0705;
}

.ant-switch {
  background: #8d8b8b;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #a9ff66;
  border-color: #a9ff66;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #a9ff66;
  border-color: #a9ff66;
}
.ant-checkbox .ant-checkbox-inner:after {
  border: 2px solid #333;
  border-top: 0;
  border-left: 0;
}
.searchInput {
  background: transparent;
  outline: none;
  border: none;
  font-size: 14px;
  width: 260px;
}
.searchInput::placeholder {
  font-size: 14px;
  color: #999;
  font-family: Gotham-Light;
}
.market {
  max-width: 80%;
  margin: 0 auto;
  background-image: url("../../asserts/imgs/market.png");
  background-repeat: no-repeat;
  background-position: right -130px;
  background-size: 600px;
}

.liquidation {
  max-width: 80%;
  margin: 0 auto;
  background-image: url("../../asserts/imgs/liquidation.png");
  background-repeat: no-repeat;
  background-position: right -40px;
  background-size: 600px;
}
.details {
  max-width: 80%;
  margin: 0 auto;
  padding: 30px 0 50px;
}
.detailInfo {
  background-image: url("../../asserts/imgs/detailsBg.png");
  background-repeat: no-repeat;
}
.bgRed {
  background: linear-gradient(
    180deg,
    rgba(77, 77, 77, 0.05) 0%,
    rgba(255, 75, 1, 0.37) 100%
  );
}
.line {
  border-bottom: 1px solid #322e2d;
}
.redBtn {
  background-color: #ff4b01;
  border-radius: 10px;
}
.activeTitle {
  color: #ff4b01;
}
.listBg {
  background: #0e0e0e;
  border-radius: 9px;
}
.listBox {
  border: 1px solid #322e2d;
  border-radius: 10px;
}
.redTips {
  background: linear-gradient(
    180deg,
    rgba(255, 75, 1, 0) 0%,
    rgba(255, 75, 1, 0) 0%,
    rgba(255, 75, 1, 0.15) 100%
  );
  border-radius: 10px;
  border: 1px solid rgba(255, 75, 1, 0.42);
  color: #ff4b01;
}

.blueTips {
  background: linear-gradient(
    180deg,
    rgba(63, 115, 255, 0) 0%,
    rgba(63, 115, 255, 0.15) 100%
  );
  border-radius: 10px;
  border: 1px solid rgba(63, 115, 255, 0.42);
  color: #3f73ff;
}
.underline {
  text-decoration: underline;
}

.ant-progress
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  color: #ff4b01 !important;
}
.ant-progress.ant-progress-circle .ant-progress-text {
  color: #fff;
  font-family: Gotham-Medium;
}
.listBorder {
  border: 1px solid #322e2d;
}
.orange {
  color: #ffc55f;
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  .noBorder {
    border: none;
  }
  .dashboard,
  .market,
  .liquidation,
  .details {
    max-width: 90%;
    background: none;
  }
  .dashboardTitle {
    padding-top: 20px;
  }
  .boxList {
    width: 100%;
  }
  .contentHome {
    min-height: 100vh;
    background-image: url("../imgs/homeBg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 60px;
  }
  .content {
    width: 100%;
    min-height: 100vh;
    background-color: #0b051a;
    padding-top: 60px;
  }

  ._mt-6 {
    margin-top: 40px;
  }
  ._white {
    color: #f4f3f9;
  }
  ._center {
    justify-content: center;
  }
  .sticky {
    width: 100%;
    padding: 0 5%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;
  }
  ._carouselBtn {
    position: inherit;
    margin: 20px auto;
    justify-content: space-around;
  }
  ._carouselBox {
    width: 100%;
    height: 180px;
  }
  ._background4 {
    background-color: #0b051a;
  }
  .w-36 {
    width: 100%;
  }
  .ml-8 {
    margin-left: 0;
  }
  .ml-16 {
    margin-left: 0;
  }
  .accountInfo {
    inset: 60px 0 auto auto !important;
  }
  .accountInfo .ant-popover-content {
    width: 280px;
  }
  ._tutorialsInfo {
    width: 90%;
    background: linear-gradient(
      180deg,
      rgba(28, 23, 42, 0.69) 1.44%,
      rgba(43, 36, 61, 0.35) 100%
    );
  }
  .h-56 {
    height: 180px;
  }
  .ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: 390px;
    margin-top: 20px;
  }
  .pl-16 {
    padding-left: 30px;
  }
  .pr-16 {
    padding-right: 30px;
  }
  ._mt-5 {
    margin-top: 20px;
  }
  ._w100 {
    width: 100%;
  }
  ._flex-col {
    flex-direction: column;
  }
  ._background-gradient4 {
    background: linear-gradient(220.09deg, #181327 56.83%, #331951 113.35%);
  }
  ._hiddenM {
    display: none;
  }
  ._size20 {
    font-size: 30px;
    text-align: center;
  }
  ._size35 {
    font-size: 35px;
    line-height: 50px;
    padding-top: 50px;
  }
  ._padding20 {
    padding: 0 20px;
  }
  ._widthM {
    margin: 40px 20px;
  }
  ._size12 {
    font-size: 12px;
  }
  ._widthM-30 {
    width: 33%;
  }
  ._footerM {
    width: 100%;
    padding: 60px 20px 30px 20px;
  }
  ._margin0 {
    margin: 0;
  }
  ._flexNo {
    display: block;
  }
  ._height20 {
    height: 20px;
  }
  ._footerList {
    height: 30px;
    margin: 30px 0 10px 0;
    font-size: 16px;
    display: flex;
    justify-content: start;
  }
  ._paddingTop10 {
    padding-top: 10px;
  }
  ._background-home {
    background-size: 80%;
  }
  ._borderNo {
    border: none;
  }
  ._paddingNo {
    padding: 0;
  }
  ._marginBottom {
    margin-bottom: 20px;
  }
  ._lineHeight30 {
    line-height: 40px;
  }
  ._referralTitle {
    background-size: 100px;
    background-position: 80% 38px;
  }
  ._marginAutoM {
    margin: 40px auto 0 auto;
  }
  ._marginLR {
    margin: 0 20px;
  }
  ._padding10 {
    padding: 20px;
  }
  ._marginTop {
    margin-top: 40px;
  }
  ._paddingB20 {
    padding-bottom: 20px;
  }
  ._paddingTB {
    padding: 50px 0;
  }
  ._borderW {
    border: 1px solid #3d3952;
  }
  ._borderS {
    border: 1px solid #ff4b01;
  }

  ._tipsTitle {
    font-size: 20px;
    color: #f4f3f9;
  }
  ._tipsBox {
    width: 90%;
  }
  ._tips {
    border: 1px solid #3d3952;
    flex-direction: column;
    padding: 20px 10px;
    border-radius: 10px;
  }
  ._tipDiv {
    width: 100%;
    flex-direction: row;
    padding-bottom: 40px;
  }
  ._desc {
    margin-top: 0;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    margin-left: 20px;
  }
  .w-16 {
    width: 48px;
  }
  ._backgroundNo {
    background-color: transparent;
  }
  ._paddingBNo {
    padding-bottom: 0;
  }
  ._flexM {
    flex-direction: column;
    padding: 0 20px;
    text-align: left;
  }
  ._flexM > div {
    width: 100%;
    border: none;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid #2a2539;
  }
  ._flexM > div:last-child {
    border-bottom: none;
  }
  ._M100 {
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  ._homeListM {
    flex-direction: column;
    margin-top: 10px;
    text-align: center;
  }
  ._homeListM > div {
    width: 100%;
    align-self: stretch;
  }
  .p-6 {
    padding: 20px;
  }
  ._listBtn {
    width: 100%;
    height: 50px;
    border: 1px solid #7f00ff;
    margin: 30px 0;
    font-size: 16px;
    font-weight: 500;
    background: rgba(54, 26, 88, 1);
  }
  ._borderLine {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  ._popoverBtn {
    text-align: center;
  }
  .ant-drawer .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer .ant-drawer-content {
    background: rgba(17, 17, 17, 0.9);
    color: #ffffff;
  }
  .ant-drawer .drawerTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #2a2539;
  }
  ._justB {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
  }
  ._justA {
    justify-content: space-around;
  }
  ._yellow {
    color: #e6a62c;
  }
  ._orderInfo {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  ._orderInfo > div {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /* ._orderInfo > div > span:last-child {
    color: #fff;
    font-weight: 500;
  } */
  ._orderInfo > div > button {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }
  .ml-10 {
    margin: 0;
  }
  .ml-5 {
    margin-left: 0;
  }
  .h-24 {
    height: auto;
  }
  ._pt-10 {
    padding-top: 10px;
  }
  ._pt-20 {
    padding-top: 20px;
  }
  ._pb-10 {
    padding-bottom: 10px;
  }
  .ant-modal-root .ant-modal-centered .ant-modal {
    width: 90vw !important;
  }
  ._flexM2 {
    flex-direction: column;
  }
}

.btnStyle{
  background: rgba(216,216,216,0);
  box-shadow: inset 0px 1px 8px 4px rgba(255,255,255,0.1);
  border-radius: 10px;
  border: 1px solid rgba(255,255,255,0.25)
}

.ant-table-wrapper .ant-table {
  background-color: transparent;
  color: #fff;
}
.ant-table-wrapper .ant-table .ant-table-tbody {
  font-weight: bold;
  font-size: 16px;
}
.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
  font-family: Gotham-Light;
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background-color: transparent;
}
.ant-table-wrapper td.ant-table-column-sort {
  background-color: transparent;
}
.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background-color: transparent;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: transparent;
}
.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1)
}
.ant-table-wrapper .ant-table-column-sorter {
  color: rgba(255, 255, 255, 0.3);
}
.ant-table-wrapper .ant-table-column-sorter:hover {
  color: #fff;
}
.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.ant-table-wrapper .ant-table-column-sorter-up.active, .ant-table-wrapper .ant-table-column-sorter-down.active {
  color: #fff;
}
.ant-table-wrapper .ant-table-tbody >tr >th, .ant-table-wrapper .ant-table-tbody >tr >td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.anticon {
  color: rgba(255, 255, 255, 0.3);
}
.ant-table-wrapper .ant-table-column-title {
  flex: none;
}
.ant-table-wrapper .ant-table-column-sorters {
  justify-content:flex-start
}



@media screen and (min-width: 500px) and (max-width: 5000px) {
  .ant-modal-root .ant-modal-centered .ant-modal {
    width: 420px;
  }
  .h-24 {
    height: 6rem;
  }
  ._hiddenP {
    display: none;
  }
  ._widthP {
    max-width: 1100px;
  }
  ._widthPMax {
    max-width: 1300px;
  }
  ._marginAuto {
    margin: 80px auto;
  }
  ._marginAuto0 {
    margin: 0 auto;
  }
  ._lineHeight30 {
    line-height: 60px;
  }
  ._referralHome {
    max-width: 1182px;
    margin: 0 auto;

    background-repeat: no-repeat;
    background-size: 420px;
    background-position: top right;
  }
}
